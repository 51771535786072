var use_preorder_with_alert = true;
var global_id_transporteur = '';

var isWeddingSpace = true;

/* functions */
function changeLocale(locale, uri) {
    setTimeout(function () {
        $.ajax({
            url: path_relative_root + "ajax_get_uri_by_lang.php",
            type: "post",
            data: { lang: locale, url: uri },
            success: function (data) {
                if (data != "") {
                    var force = "";
                    if (uri == "/") {
                        force = "?force";
                    }

                    var href_lang = data + force;

                    if (typeof href_change_lang != "undefined") {
                        href_lang = href_change_lang + href_lang;
                    }

                    location.href = href_lang;
                }
            },
        });
    }, 400);
}

/* PRESSE */
function showPresse() {
    var zoom = $(this).next(".presse_zoom");

    if (zoom.length > 0) {
        $(".presse_zoom").removeClass("actif");

        zoom.addClass("actif open");

        $("#shade").addClass("visible");

        if ($("#shade").is(":visible")) {
            $("#shade").on("click", function () {
                closePresse();
            });
        }
    }
}

function closePresse() {
    var zoom = $(".presse_zoom.actif");

    zoom.removeClass("actif open");

    if ($("#shade").is(":visible")) {
        $("#shade").removeClass("visible");
    }
}

function showhide_search() {
    if ($('#top_search').hasClass('activated')) {
        $('#top_search').css('display', 'none');
        $("#shade").removeClass('visible');
        $("body").addClass('search_hidden').css('overflow', 'auto');
        $(".banner_wrapper, .bandeauMenuAffix").css('z-index', 30);
        $("body").css('position', 'initial');
    }
}

/**
 * Ajout le fonction faqinit()
 * sur mobile
 */
function faqinit(array_questions) {

    $(document).on('change keyup focus', '#faq_search', function () {

        showTabFaq();

        var search = $(this).val();

        if (search && search.length > 3) {

            $('.search_results li').show().removeClass("hasMatch");
            var array_match = [];

            // On affiche uniquement celles qui correspondent à la recherche
            $.each(array_questions, function (i, val) {

                val["nom"] = val["nom"].toUpperCase();
                var match = val["nom"].match(search.toUpperCase());

                if (match) {
                    array_match.push(match);
                    $('.search_results').show();
                    $('li#brand_' + val['id']).addClass("hasMatch");
                }

            });

            $('.brand_item:not(.hasMatch)').hide();

            if (array_match.length == 0) {
                $('.search_results').hide();
            }

        } else {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });

    var click_in_process = false;

    $('.search_results').mousedown(function () {
        click_in_process = true;
    });

    $('.search_results').mouseup(function () {
        click_in_process = false;
        $('#faq_search').focus();
    });

    $('#faq_search').blur(function () {
        if (!click_in_process) {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });

    $('#theme_global .theme').on('click', function () {
        var id = $(this).data('id');
        var theme = $('#theme_page');
        $('#theme_global').removeClass('actif');
        $('.title_detail_sub').css('display', 'none');
        theme.addClass('actif');
        showTabFaq(id, 1)
    });

    $('#theme_page .theme').on('click', function () {
        var id = $(this).data('id');
        showTabFaq(id, 1);
    });
}

function researchFaq(node_id, level, question_id) {

    $('.search_results').hide();
    $('.search_results li').show().removeClass("hasMatch");

    if ($('#theme_global').hasClass("actif")) {
        $('#theme_global').removeClass('actif');
        $('#theme_page').addClass('actif');
    }

    if (!$(".faq_theme_" + node_id + "").hasClass("active")) {
        showTabFaq(node_id, level);
    }
    showTabFaq(node_id, level, question_id);
    $("html, body").animate({ scrollTop: $(".faq_question_" + question_id + "").offset().top - 100 }, 1000);

}

function executeScroll(scrollHeight) {
    $(document).scroll(function () {
        if ($(this).scrollTop() > scrollHeight) {
            $('#floating_add_basket').removeClass('scrollUp');
            $('#floating_add_basket').addClass('scrollDown');
        }
        else {
            $('#floating_add_basket').removeClass('scrollDown');
            $('#floating_add_basket').addClass('scrollUp');
        }
    });
}

function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {

    var numDIVObjet = document.getElementById("popup_numero_retour");

    numDIVObjet.style.display = "block";
    document.getElementById("general_shade").style.display = "block";
    document.getElementById("general_shade").style.opacity = "1";

    var scrollTop = $(window).scrollTop(),
        speed = Math.min(scrollTop * 0.8, window.innerHeight);


    $("html, body").animate({ scrollTop: 0 }, (speed * 2), function () { });
}

function createEtiquetteRetour(idOrder, parentPath, multiTransp, idOrderTransp) {

    var numRetour = "";
    if (typeof idOrderTransp != "undefined")
        numRetour = ajax_file(
            parentPath +
            "ajax_num_etiquette.php?idOrder=" +
            idOrder +
            "&multiTransp=" +
            multiTransp +
            "&idOrderTransp=" +
            idOrderTransp
        );
    else
        numRetour = ajax_file(
            parentPath +
            "ajax_num_etiquette.php?idOrder=" +
            idOrder +
            "&multiTransp=" +
            multiTransp
        );


    if (numRetour != "false") {

        var transporteur = '';
        // Dans le cas du multi transporteur on définie les bloc qui doive s'afficher
        if (multiTransp == 1) {
            transporteur = '_' + global_transporteur_id;
        }
        var commentaireRetourObjet = "";
        var divCreateNumRetourObjet = document.getElementById("createNumRetour" + transporteur);
        var divPrintPdfObjet = document.getElementById("printPdf" + transporteur);

        if (document.getElementById("commentaireRetourDiv" + transporteur))
            commentaireRetourObjet = document.getElementById(
                "commentaireRetourDiv"
            ).value;

        if (multiTransp !== 1) {
            if (document.getElementById("commentaireRetour") !== null) {
                commentaireRetourObjet = document.getElementById(
                    "commentaireRetour"
                ).value;
            }
        }

        var recommand = $(".recommandation").length;

        var has_errors = false;
        $(".return_quantity").each(function () {
            var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
            return_choice.removeClass('error');

            if ($(this).val() > 0 && return_choice.val() == '0') {
                has_errors = true;
                return_choice.addClass('error');
            }
        });

        if (!has_errors) {
            if (recommand) {
                $(".recommandation p span").css("color", "#000");
                $('p[id^="liste_motif_retour"] .select').css("border", "none");
                $("#divBtnPrintPdf").show();
            }

            data = $('#return_products_form').serialize();
            data += '&idOrder=' + idOrder + '&multiTransp=' + multiTransp + '&return_number=' + numRetour;

            $.ajax({
                url: parentPath + 'ajax_create_etiquette.php',
                type: 'post',
                data: data,
                success: function (res) {
                    location.reload();
                }
            });
        } else {
            if (recommand) {
                $(".recommandation p span").css("color", "#a40000");
                $('p[id^="liste_motif_retour"] .select').css(
                    "border",
                    "2px solid #a40000"
                );
            }
        }

    }
}

function closeMultiShad(id) {

    $('#shade, #general_shade').off('click');

    // Hide modbox and shade and destroy shade's click event
    $('#' + id).slideUp("slow").queue(function () {

        if ($('#shade').is(":visible")) {
            $('#shade').fadeTo("slow", 0, function () {

                $('#shade').css('display', 'none');
            });
        }

        if ($('#general_shade').is(":visible")) {
            $('#general_shade').fadeTo("slow", 0, function () {

                $('#general_shade').css('display', 'none');
            });
        }

        $(this).dequeue();
    });
}

/* IIFE */


var swiper0 = new Swiper("#main_slider.swiper-container", {
    pagination: ".swiper-pagination",
    paginationClickable: true,
});

if ($("body").hasClass("product_page")) {

    if (typeof gammSwiperSlider != "undefined") {
        gammSwiperSlider.destroy(true, true);
    }

    if ($(".swiper-container.gamme_product_carousel .swiper-slide").length > 0) {
        gammSwiperSlider = new Swiper(".swiper-container.gamme_product_carousel",
            {
                slidesPerView: 1,
                centeredSlides: true,
                paginationClickable: true,
                spaceBetween: 0,
                pagination: ".swiper-pagination",
                paginationClickable: true,
                preloadImages: true,
                lazyLoading: true,
                loop: true,
                nextButton: ".swiper-button-next",
                prevButton: ".swiper-button-prev",
            }
        );
    }
}

if ($("#contents_wrapper").hasClass("lookbook")) {
    $(".list_item .item_container").addClass("full");

    $("a.item_lnk").click(function () {
        event.preventDefault();
    });
}

/* RECRUTEMENT */
$("button.postuler").click(function () {
    var recruitmentForm = $(".recrutement_form");

    recruitmentForm.addClass("open");
    $("#shade").addClass("visible");
});

$(".closeRecruitment").click(function () {
    var recruitmentForm = $(".recrutement_form");

    recruitmentForm.removeClass("open");
    $("#shade").removeClass("visible");
});

/* PLACER TABLEAU FRAIS PORT DANS SA SECTION RESPECTIVE */
$('.frais_port').appendTo('.order_cgv #bloc_4');

/**
* Swiper mobile "Mon compte" menu
*/
if ((
    $('body').hasClass('customer') ||
    $('body').hasClass('wishlist') ||
    $('body').hasClass('parrainage_index') ||
    $('body').hasClass('newsletter') ||
    $('body').hasClass('order')
    ) && $('#menu_account_slider_mob').length > 0) {

    var menuInitialSlide = 0;

    $('.menu_account_slider_mob').css({ 'display': 'block', 'opacity': '0' });

    $('.menu_account_slider_mob .swiper-slide').each(function (i, obj) {
        if ($(obj).hasClass('activeMenu')) {
            menuInitialSlide = i;
        }
    });
    var menuAccSwiperMob = new Swiper('#menu_account_slider_mob', {
        slidesPerView: 'auto',
        initialSlide: menuInitialSlide,
        nextButton: '#menu_account_slider_mob_wrapper .swiper-button-next',
        prevButton: '#menu_account_slider_mob_wrapper .swiper-button-prev',
        slidesOffsetBefore: 30,
        slidesOffsetAfter: 30,
        onInit: function () {
            $('.menu_account_slider_mob').fadeTo("slow", 1);
        },
    });
}

if ($('.rayonCategSliders .swiper-slide').length > 1) {
    var rayonInitialSlide;
    $('.rayonCategSliders .swiper-slide').each(function (i, obj) {
        if ($(obj).hasClass('actif')) {
            rayonInitialSlide = i;
        }
    });
    var rayonCategSliders = new Swiper('.rayonCategSliders', {
        loop: false,
        slidesPerView: '2.25',
        centeredSlides: true,
        initialSlide: rayonInitialSlide,
        nextButton: '.swiper-button-next.new',
        prevButton: '.swiper-button-prev.new'
    });
}
/*/ Put bandeauMenuAffix after header/*/
$(".bandeauMenuAffix").insertAfter("#site_head");
$(".bandeauMenuAffix").css("opacity","1");
// Close bandeauMenuAffix
$(".bandeauMenuAffix .closeBtn").on("click", function(){
    $(this).parent().hide();
    $("body").css("margin-top","-2.6rem");
    $("#top_search").css("top","0.7rem");
});

$('#cart_btn').parent().addClass('display-defer');
$('#search_icon').addClass('display-defer');



setTimeout(function(){
    $('#search_icon').each(function () {
        $(this).click(function () {
            $('#top_search').addClass('activated').css('display', 'block');
            $("body").removeClass('search_hidden').css('overflow', 'hidden');
            $(".banner_wrapper, .bandeauMenuAffix").css('z-index', 105);
            $(".banner_wrapper").css('overflow', 'unset');
            $('#shade').addClass('visible');
            $("body").css('position', 'fixed');
        });
    });
}, 1000)

$('#shade, #form_recherche .close_search').click(function () {
    if ($('#top_search').hasClass('activated')) {
        $('#top_search').css('display', 'none');
        $("#shade").removeClass('visible');
        $("body").addClass('search_hidden').css('overflow', 'auto');
        $(".banner_wrapper, .bandeauMenuAffix").css('z-index', 30);
        $("body").css('position', 'initial');
    }
});




// JS pour activer les 3 conseil d'entretien
$('#conseil_swipper .title_ctn .big_title_charte span').each(function () {
    $(this).click(function () {

        $('.sat_article').removeClass('active');
        $('.big_title_charte').removeClass('active');
        $(this).parent().addClass('active');

        if ($(this).parent().parent().hasClass('swiper-slide-active')) {
            $(this).parent().addClass('active');
        }

        if ($(this).parent().hasClass('article1')) {
            $('.sat_article').removeClass('active');
            $('#article1').addClass('active');
        }

        if ($(this).parent().hasClass('article2')) {
            $('.sat_article').removeClass('active');
            $('#article2').addClass('active');
        }

        if ($(this).parent().hasClass('article3')) {
            $('.sat_article').removeClass('active');
            $('#article3').addClass('active');
        }

    });

})

$('#geoloc_btn').wrap('<div class="header_section">');

// SWIPER page conseil d'entretien
var conseil_swipper = new Swiper('#conseil_swipper.swiper-container', {
    slidesPerView: 2,
    initialSlide: 0,
    centeredSlides: true,
    slideToClickedSlide: true,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev'
});

$(document).ready(function () {
    // Footer accordion
    $('.footer_accordion_ctn h1.title').each(function(){
        $(this).click(function(){
            if($(this).hasClass('active')){
                $(this).removeClass('active');
                $(this).parent().find('.footer_navs').removeClass('active').slideUp(300);
            }
            else{
                $('.footer_accordion_ctn h1.title').removeClass('active');
                $('.footer_accordion_ctn .footer_navs').removeClass('active').slideUp(300);
                $(this).addClass('active');
                $(this).parent().find('.footer_navs').addClass('active').slideDown(300);
            }
        });
    });
});

// Order detail total product
$("#total_prdct").click(function () {
    $(this).toggleClass("closed");
    $(".cart_item_container").slideToggle("slow");
});

// Instagram
if ($("#sliderHomeInsta").length > 0) {
    var sliderHomeInsta = new Swiper('#sliderHomeInsta', {
        slidesPerView: 3,
        spaceBetween: 31,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
}

/**
 * Ajout class ".checked" sur .wrapper_cadre_product
 */
$(".check").click(function (ev) {
    if (!$(this).is(".checked")) {
        $(this).closest('.wrapper_cadre_product').addClass('checked');
    } else if ($(this).is(".checked")) {
        $(this).closest('.wrapper_cadre_product').removeClass('checked');
    }
});

$("#selectAll").click(function (ev) {
    $(".check").each(function () {
        $(this).closest('.wrapper_cadre_product').addClass('checked');
    });
});

$("#unselectAll").click(function (ev) {
    $(".check").each(function () {
        $(this).closest('.wrapper_cadre_product').removeClass('checked');
    });
});

if ($('.bandeauMenuAffix').length > 0) {
    $('body').addClass('bandeau_preheader_on');
    $('.bandeauMenuAffix').css('top', '6.593rem');
    $('.block_menu.rayonCategSliders').css('margin-top', '7rem');
}

$(document).scroll(function () {
    if ($(this).scrollTop() > 900) {
        $('#floating_add_basket').removeClass('scrollUp');
        $('#floating_add_basket').addClass('scrollDown');
    }
    else {
        $('#floating_add_basket').removeClass('scrollDown');
        $('#floating_add_basket').addClass('scrollUp');
    }
});

if ($(window).width() == 320) {
    executeScroll(400);
};
if ($(window).width() == 375) {
    executeScroll(450);
};
if ($(window).width() == 414) {
    executeScroll(680);
};
if ($(window).width() == 568) {
    executeScroll(680);
};
if ($(window).width() == 667) {
    executeScroll(780);
};
if ($(window).width() == 736) {
    executeScroll(950);
};
if ($(window).width() == 812) {
    executeScroll(1250);
};

if ($('#popup_numero_retour').length == 0) {
    $('.btn_container .button').click(function () {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active').next("div").removeClass("active");
            $(this).next("div").slideToggle();
        }
        else {
            $(this).addClass('active').next("div").addClass("active");
            $(this).next("div").slideToggle();
        }
    });
}

$('.dispo_en_mag').insertAfter('#link_book_in_store');

var checkLength = $('body.product_page .price_tag.old_price').text().trim().length;
$('.price_tag.new_price').css('margin-left', '0.4rem');
if (checkLength > 139) {
    $('body.product_page .price_tag').css('display', 'inline-block');
}

/*STOR LOC MOB SWIPER*/
if ($('#bloc_reviews_slider').length > 0) {
    window.reviewsSliderMob = new Swiper('#bloc_reviews_slider', {
        slidesPerView: 1,
        loop: true,
        nextButton: '.storloc_swiper-button-next',
        prevButton: '.storloc_swiper-button-prev'
    });
}

// Footer accordion
$('#left_menu .menu_item .bloc_lnk.user.connected').each(function () {
    $(this).on('click touch tap', function () {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this).parent().find('.dropdown.mon-compte').removeClass('active').slideUp(300);
        }
        else {
            $('#left_menu .bloc_lnk.user.connected').removeClass('active');
            $('.dropdown.mon-compte').removeClass('active').slideUp(300);
            $(this).addClass('active');
            $(this).parent().find('.dropdown.mon-compte').addClass('active').slideDown(300);
        }
    });
});

setTimeout(function(){
    var pushCategSlider = new Swiper('.pushCategSlider.swiper-container ', {
        slidesPerView: 4.4,
        spaceBetween: 30,
        //loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
},500);
if($('.asso_product_carousels .swiper-slide').length > 3){
    $('.btn_assos_carousels').show();
    $('.asso_product_carousels').removeClass('center_slides');
    
    var asso_product_carousels_mobile = new Swiper('.asso_product_carousels.swiper-container ', {
        slidesPerView: 2.25,
        loop: false,
        centeredSlides: true,
        spaceBetween:25,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
    });

    asso_product_carousels_mobile.slideTo(1, true);
}

    var asso_product_carousels = new Swiper('#wrapper_products_home_category .swiper-container ', {
    slidesPerView: 2.25,
    loop: false,
    centeredSlides: true,
    spaceBetween:25,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
});

$('#derniers_articles_vus').trigger("click");
var productVisitedSwiper = new Swiper('#productVisitedSwiper.swiper-container ', {
    slidesPerView: 1
});
$('#carousel_wrapper .ranges_carousel_title').click();

var productVisualMulti = new Swiper('.productVisualMulti.swiper-container ', {
    slidesPerView: 1
});

var productVisualMulti = new Swiper('.productVisualMulti.swiper-container ', {
    slidesPerView: 1
});

$('#carousel_wrapper .ranges_carousel_title, #carousel_wrapper .asso_product_carousels').addClass('active');

$('#carousel_wrapper .ranges_carousel_title').click(function () {
    if (!$(this).hasClass('active')) {
        $('#derniers_articles_vus, #productVisitedSwiper').removeClass('active');
        $(this).addClass('active');
        $(this).parent().find('.asso_product_carousels').addClass('active');
    }
});

$('#derniers_articles_vus').click(function () {
    if (!$(this).hasClass('active')) {
        $('#carousel_wrapper .ranges_carousel_title, #carousel_wrapper .asso_product_carousels').removeClass('active');
        $(this).addClass('active');
        $('#productVisitedSwiper').addClass('active');
    }
});

$('.wrap_product_bottom #productTitle').each(function () {
    var productTitleLength = $(this).text().length;
    if (productTitleLength > 35) {
        $(this).addClass('ellipsis');
    }
});

$(".wrap_product_bottom .wrap_txt").each(function () {
    $(this).find('.wrap_price').insertBefore($(this).find('#productTitle'));
});

$('#productVisitedSwiper, .asso_product_carousels').addClass('disapear');


$(".row .content").click(function () {
    if ($(this).hasClass('open')) {
        $(this).removeClass('open');
        $(this).find('.content_inside').removeClass('show');
        $(this).find('.frais_port').removeClass('show');
    } else {
        $(this).addClass('open');
        $(this).find('.content_inside').addClass('show');
        $(this).find('.frais_port').addClass('show');
    }
});

if($('.category div.toggleNumObjects span').length){
    $('.category div.toggleNumObjects span').click(function(){
        $('div.item_container .wrapper_img img').each(function () {
            var imgSrc = $(this).attr('src');
            var mediumSrc = imgSrc.replace(/mini/, 'medium');
            var miniSrc = imgSrc.replace(/medium/, 'mini');

            if($('.category div.item_container').hasClass('full')){
                $(this).attr('src', mediumSrc);
            } else if(!$('div.item_container').hasClass('full')){
                $(this).attr('src', miniSrc);
            }
        });
    });
}


if($('.bloc_bank_contents').length){
    $('.bloc_bank_contents').on('click touch', function () {
        $('.bloc_bank_contents form').submit();
    });
}


function imageSrcModifier() {
    if($(' div.toggleNumObjects span').length){
        $(document).on('DOMSubtreeModified', '.ill_img', function () {
            setTimeout(function () {
                if($('.ill_img').hasClass('imgLoaded')){
                    $(' div.item_container .wrapper_img img').each(function () {
                        var imgSrc = $(this).attr('src');
                        var mediumSrc = imgSrc.replace(/mini/, 'medium');
                        var miniSrc = imgSrc.replace(/medium/, 'mini');
                
                        if($(' div.item_container').hasClass('full')){
                            $(this).attr('src', mediumSrc);
                        } else {
                            $(this).attr('src', miniSrc);
                        }
                    });
                }
            }, 1000);
        });
    }
}

imageSrcModifier();

if($('.category div.toggleNumObjects span').length){
    paginationGoToPage(function () {
        imageSrcModifier();
    });
}

function imageSearch() {
    if($('.search div.toggleNumObjects span.oneProduct').hasClass('actif')){
        $('.search div.item_container').addClass('full');
    }
}
imageSearch();

// Creates pager in shop's districts
function paginate(page) {

    if(!document.getElementById('filters_form'))
        return;

    var form = document.getElementById('filters_form'),
            nb_itm = parseFloat(document.getElementById('nbr_items').innerHTML),
            nb_total_page = Math.ceil(form.totalElems.value / form.nb_prod.value),
            pagination = $(document.getElementById('pagination_content'));

    page = page || 1;

    $.ajax({
        url: path_relative_root + 'ajax_reload_pagination.php',
        type: 'get',
        data: {
            page: page,
            link: 'paginationGoToPage()',
            nb_total_page: nb_total_page
        },
        success: pagination.html.bind(pagination),
        complete: function () {
          if ( $('#pagination_content .pagination').length > 0) {
              $('.see_all').show();
          } else {
              $('.see_all').hide();
          }

            imageSrcModifier();
            imageSearch();
        }
    });
}

// Sends message
function sendMessage(id, message_send, message_fail) {

    var errbox = document.getElementById('item_error');
    var form = $('#form_reply')[0];
    var  fd = new FormData(form);
    var objElement = document.getElementById("loader_1");
    var item_error = document.getElementById('item_error');
    if($('#msg_txt_' + id).val() == '') {

        $('.modbox_title', errbox).html("Veuillez saisir votre réponse");
        objElement.style.display = "none";
        item_error.style.display = "block";
        openModalBox.call(errbox);
        return false;
    }
    if ($('#msg_txt_' + id).val() != '') {

        $.ajax({
            type : 'post',
            url : path_relative_root + 'message-detail.php',
            data: fd,
            processData: false,  // dire à jQuery de ne pas traiter les données
            contentType: false,   // dire à jQuery de ne pas définir le contentType
            cache: false,
            success : function (response) {

                if (response == '1') {
                    item_error.style.display = "block";
                    $('.modbox_title', errbox).html(message_send);
                    modBoxOpen.call(errbox);
                    window.setTimeout(function () {
                        window.location.reload();
                    }, 3000);

                } else {
                    $('.modbox_title', errbox).html(message_fail);
                    modBoxOpen.call(errbox);
                }
            }
        });
    } else {
        $('.modbox_title', errbox).html(message_fail);
        modBoxOpen.call(errbox);
    }


}

function uploadfile(){//fonction pour valider le fichier en pj dans le formulaire message

    var error = false;
    var image = $('#imagepj1')[0].files[0];
    var fsize = typeof image != "undefined" ?  image.size : 0;; //get file size
    var ftype = typeof image != "undefined" ? image.type : ''; // get file type
    var  reset_image = document.getElementById('reset_image');
    var allowedType = new Array('image/jpeg', 'image/png'); // Type de fichiers autoris�s
    if ( fsize>4000000 ){
        alert('Poids Max : 4Mo');
        error =  true;
    }else if (jQuery.inArray(ftype, allowedType) == -1){
        alert('Format autorisé : jpg, png');
        error =  true;
    }
    if( error ){
        $('#imagepj1').val('');
    }else{
        $('.msgBox .image_msg p').text($('#imagepj1')[0].files[0].name);
        reset_image.style.display="block";
    }
}

function addFileInput2(id_input,string){

    var field = (typeof(string) != 'undefined') ? string:'contactProduitPhoto';

    id = parseInt(id_input);
    var next_file = id + 1;
    var file = $('#'+field+id_input)[0].files[0];
    var error = false;
    var input = field == 'contactProduitPhoto' ? $('#photoProduit_display') : $('#photoProduitEndommage_display');
    var nb_input = field == 'contactProduitPhoto' ? $(input).val() : $(input).val()  ;
    var  reset_file = document.getElementById('reset_file');
    nb_input = parseInt(nb_input);
    var size = typeof file != "undefined" ?  file.size : 0;
    var type = typeof file != "undefined" ? file.type : '';
    var allowedType = new Array('image/jpeg', 'image/png', 'application/pdf'); // Type de fichiers autoris�s
    if (size>4000000){
        alert('Poids Max : 4Mo');
        error =  true;
    }else if (jQuery.inArray(type, allowedType) == -1){
        alert('Format autorisé: jpg, png, pdf');
        error =  true;
    }
    if (error) {
        if( id_input == 1 ){
            $('#field_send').addClass('cache');
            $('#'+field+next_file).remove();
        }
        $('#'+field+id_input).val('');
    } else {

        if( id_input == 1 ){
            $('#field_send').removeClass('cache');
            if(field == 'filepj') {
                $('.msgBox .file_msg p').text($('#filepj1')[0].files[0].name);
                reset_file.style.display = "block";
            }
            if(field == 'contactProduitPhoto') {
                $('.msgBox .file_msg p').text($('#contactProduitPhoto1')[0].files[0].name);
                reset_file.style.display = "block";
            }

            if(field == 'contactProduitPhotoEndommage') {
                $('.msgBox .file_msg p').text($('#contactProduitPhotoEndommage1')[0].files[0].name);
                reset_file.style.display = "block";
            }
        }
        if (field != "filepj") {
            $(input).attr("value", next_file);
        }

    }
}

function resetFile(id_input) {

    if (id_input == "filepj1") {
        var  file = document.getElementById('filepj1');
        var  reset_file = document.getElementById('reset_file');
        reset_file.style.display = "none";
        $('.msgBox .file_msg p').text('');
        file.value = '';
    }
    if (id_input == "imagepj1") {
        var  image = document.getElementById('imagepj1');
        var  reset_image = document.getElementById('reset_image');
        reset_image.style.display = "none";
        $('.msgBox .image_msg p').text('');
        image.value = '';
    }

    if(id_input == 'contactProduitPhotoEndommage') {
        var  file2 = document.getElementById('contactProduitPhotoEndommage1');
        var  reset_file2 = document.getElementById('reset_file');
        reset_file2.style.display = "none";
        $('.msgBox .file_msg p').text('');
        file2.value = '';
    }

    if(id_input == 'contactProduitImageEndommage') {
        var  image2 = document.getElementById('contactProduitImageEndommage1');
        var  reset_image2 = document.getElementById('reset_image');
        reset_image2.style.display = "none";
        $('.msgBox .image_msg p').text('');
        image2.value = '';
    }

}

$(document).ready(function () {
    /* JS HOME MOBILE*/
    /* SWIPER MODULE COVER*/
    if($('#super_home_swiper .swiper-slide').length > 1){
        setTimeout(function(){
            var superHomeSwiper = new Swiper('#super_home_swiper.swiper-container', {
                slidesPerView: 1,
                loop: false,
                autoplay: 3000,
                speed: 1500,
                pagination: ".swiper-pagination",
                paginationClickable: true,
            });
        }, 100);
    } 

    if($('#swiper_image_image_image .swiper-slide').length > 1){
        var comma_separator_number_step = $.animateNumber.numberStepFactories.separator(' ');
        var a = 0;

        $(window).scroll(function(){
            animate_numbers();
        });

        animate_numbers();

        function animate_numbers(){
            if($('.image-image-image').length>0){
                var oTop = $('.image-image-image').offset().top - window.innerHeight;
                if (a == 0 && $(window).scrollTop() > oTop) {
                    /* Number increasing effect */
                    $('.image-image-image .home-module-number .number').each(function (){
                        $(this).prop('number', 0).animateNumber(
                        {
                            easing: 'swing',
                            number: $(this).text(),
                            numberStep: comma_separator_number_step
                        },
                        2000
                        );
                    });
                    a = 1;
                }
            }
        }

        setTimeout(function(){
            var modImg3Swiper = new Swiper('#swiper_image_image_image.swiper-container', {
                autoplay: 3000,
                speed: 1500,
                initialSlide: 1,
                slidesPerView: 2,
                spaceBetween: 40,
                loop: false,
            });
        }, 100);
    }

    if($('#swiper_multi_img_titre_txt .swiper-slide').length > 1){
        setTimeout(function(){
            var modMultiImgTitreTxtSwiper = new Swiper('#swiper_multi_img_titre_txt.swiper-container', {
                initialSlide: 1,
                slidesPerView: 'auto',
                centeredSlides: true,
                loop: false,
            });
        }, 100);
    }

    if($('#coverMultiple .swiper-slide').length > 1){
        setTimeout(function(){
            var coverMultiple = new Swiper('#coverMultiple.swiper-container', {
                slidesPerView: 'auto',
                initialSlide: 1,
                centeredSlides: true,
                spaceBetween: 30,
                speed: 300,
                autoplay: 3000,
            });
        }, 100);
    }
    /* END JS HOME MOBILE*/

    $("#block_news .home-columns-container article.bg-column").remove();

    /**@ Page partenaire go to the top of the page */
    $('.partenaires .partenaires_menu #GoToTop').click(function (ev) {
        ev.preventDefault();
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
    });

    /**@ Page partenaire menu */
    $('.partenaires .partenaires_menu_lists .menu_list_content li').click(function (ev) {

        var link_ref = $(this).find('a').attr('href');;

        $('html, body').animate({
            scrollTop: $(link_ref).offset().top - 60
        });

    });

    /**@ Page partenaire - open menu lists */
    $('.partenaires .partenaires_menu #part_category').click(function (ev) {
        var part_category = $(this);
        ev.preventDefault();
        part_category.parent().addClass("hide");
        $("#shade").addClass("visible");
        if ($("#shade").is(":visible")) {
            $("#shade").on("click", function () {
                $("#shade").removeClass("visible");
                part_category.parent().siblings(".partenaires_menu_lists" ).removeClass("open");
            });
        }
        part_category.parent().siblings(".partenaires_menu_lists" ).addClass("open");
    });

    /**@ Page Partenaire - close div */
    $('.partenaires .partenaires_menu_lists .menu_list_title').click(function (ev) {
        //ev.preventDefault();
        if ($("#shade").is(":visible")) {
            $("#shade").removeClass("visible");
        }
        $(this).parents(".partenaires_menu_lists" ).siblings(".partenaires_menu ").removeClass("hide");
        $(this).parents(".partenaires_menu_lists" ).removeClass("open");
    });

    /**@ Page Partenaire - after selecting a menu close div */
    $('.partenaires .partenaires_menu_lists .menu_list').click(function (ev) {
        //ev.preventDefault();
        if ($("#shade").is(":visible")) {
            $("#shade").removeClass("visible");
        }
        $(this).parents(".partenaires_menu_lists" ).siblings(".partenaires_menu ").removeClass("hide");
        $(this).parents(".partenaires_menu_lists" ).removeClass("open");
    });

});

// On supprime le menu catalogue si on est pas dedans
if (($("body").hasClass('category') || $("body").hasClass('product_page') || $("body").hasClass('super_boutik'))==false) {
    $("#main_menu").remove();
}

// Update underlying hidden inputs on date inputs
function dateUpdate(val) {

    var rex = {
        us: new RegExp('\\d{4}(?:-\\d{2}){2}'),
        fr: new RegExp('(?:\\d{2}\\/){2}\\d{4}')
    },
        date;

    if ( val && ( rex.us.test(val)) || ( rex.fr.test(val))) {

        if (rex.us.test(val)) {

            date = val.split('-');
        } else {

            date = val.split('/').reverse();
        }

        $('[name="clientDatenaisJ"]', this).val(date[2]);
        $('[name="clientDatenaisM"]', this).val(date[1]);
        $('[name="clientDatenaisA"]', this).val(date[0]);
        $('#clientDatenais').val(date[0] + '-' + date[1] + '-' + date[2]);
        $('#clientDatenaisVisible').val(date[2] + '/' + date[1] + '/' + date[0]);
    } else {
        this.value = 'dd/mm/yyyy';
    }
}

var datefield = document.createElement("input")

datefield.setAttribute("type", "date")

var maxBirthdayDate = new Date();
maxBirthdayDate.setFullYear(maxBirthdayDate.getFullYear() - 18);

if (datefield.type != "date" || $('body').hasClass('mobile')) { //if browser doesn't support input type="date", load files for jQuery UI Date Picker
    document.write('<link href="http://ajax.googleapis.com/ajax/libs/jqueryui/1.12.1/themes/base/jquery-ui.css" rel="stylesheet" type="text/css" />\n')
    document.write('<script src="http://ajax.googleapis.com/ajax/libs/jqueryui/1.12.1/jquery-ui.min.js"><\/script>\n')
}
if (datefield.type != "date" || $('body').hasClass('mobile')) { //if browser doesn't support input type="date", initialize date picker widget:
    $(document).ready(function () {
        $('#clientDatenaisVisible').datepicker({
            maxDate: maxBirthdayDate,
            dateFormat: 'dd/mm/yy'
        });
    });
}


$(document).ready(function () {

    // Form demo gratuite init required input
    initFormDemoGratuite();

    if ($('body').hasClass('rendez-vous')) {
        //@Todo: fetch public & busy days via ajax
        var disabledDays = [];
        var busyDays = [];

        jQuery.datepicker.regional['fr'] = {
            closeText: 'Fermer',
            prevText: '&#x3c;Pr&eacute;c',
            nextText: 'Suiv&#x3e;',
            currentText: 'Aujourd\'hui',
            monthNames: ['Janvier', 'F&eacute;vrier', 'Mars', 'Avril', 'Mai', 'Juin',
                'Juillet', 'Ao&ucirc;t', 'Septembre', 'Octobre', 'Novembre', 'D&eacute;cembre'
            ],
            monthNamesShort: ['Jan', 'F&eacute;v', 'Mar', 'Avr', 'Mai', 'Jun',
                'Jul', 'Ao&ucirc;', 'Sep', 'Oct', 'Nov', 'D&eacute;c'
            ],
            dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
            dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
            dayNamesMin: ['di', 'lu', 'ma', 'me', 'je', 've', 'sa'],
            weekHeader: 'Sm',
            dateFormat: 'yy-mm-dd',
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            hideIfNoPrevNext: true,
            minDate: '+0d',
            maxDate: '+30d',
            yearSuffix: ''
        };

        jQuery.datepicker.setDefaults(jQuery.datepicker.regional['fr']);

        var dates = $('#datepicker').datepicker({
            beforeShowDay: function (date) {
                return nationalDays(date)
            },
            onSelect: function (date) {
                chosenDate(date);
            }
        });
        // utility functions 
        function nationalDays(date) {
            var m = date.getMonth(),
                d = String(date.getDate()),
                y = date.getFullYear();
            m = String(m + 1);
            if (m.length == 1) {
                m = '0' + m;
            }
            if (d.length == 1) {
                d = '0' + d;
            }
            if (disabledDays.length > 0) {
                for (i = 0; i < disabledDays.length; i++) {
                    if ($.inArray(y + '-' + m + '-' + d, disabledDays) != -1 || new Date() > date) {
                        return [false];
                    }
                }
            }
            if (busyDays.length > 0) {
                for (i = 0; i < busyDays.length; i++) {
                    if ($.inArray(y + '-' + m + '-' + d, busyDays) != -1 || new Date() > date) {
                        return [false];
                    }
                }
            }
            return [true];
        }
    }


    var $div = $("#parrainage_form .bloc_parrainage_form .w-submit button.w-submit-button span");
    $($div).text($($div).text().replace("+", ""));

 });

/**
 * Formulaire demo gratuite
 * pre selection du service
 * requête ajax mail
 */
function initFormDemoGratuite() {
    var demoGratuiteForm = $('#demo_gratuit form');
    var select = demoGratuiteForm.find('select[name=form_service]');
    var headerTitle = $('#demo_gratuit .header_lightbox .txt_alert');
    // Add mail object
    var input = document.createElement("input");
    input.type = "hidden";
    input.name = "faq_question";
    input.value = translate('obj_mail_contact_demo');
    $('#demo_gratuit form')[0].appendChild(input);
    // If form demo gratuite exist
    if (select.length) {
        // Initialize form_service 'Demander une démo' with vue js ref (wDemoGratuiteForm)
        wShop.$refs.wDemoGratuiteForm.form.form_service = 17;
        // Check submit for AJAX
        demoGratuiteForm.on('submit', function(e) {
            e.preventDefault();  // Prevent form from submitting
            // Start loading
            wShop.$refs.wDemoGratuiteForm.isLoading = true;
            // Get datas form
            var data = demoGratuiteForm.serialize();

            // Start AJAX
            $.ajax({
                url : path_relative_root + 'ajax_send_demo_gratuite_form.php',
                type : 'post',
                data : data,
                success : function (res) {
                    // End loading
                    wShop.$refs.wDemoGratuiteForm.isLoading = false;
                    // Display validation OR error sending message
                    if (res) {
                        demoGratuiteForm.hide();
                        $('#demo_gratuit').addClass('mail_sent');
                        headerTitle.html(translate('title_demo_success') + "<span class='desc success'>"+translate('desc_demo_success')+"</span>")
                    } else {
                        $("#mes_err_fieldset_demo").show();
                        $("#message_mail_demo .error").show();
                    }
                }
            });
        });

        setTimeout(function() {
            let hash = window.location.hash;
            if (typeof hash != 'undefined' && hash != null && hash != '') {
                hash = hash.substring(1);
                if (hash === "demo") { // Open lightbox demo when hash #demo is detected WP-25072
                    openMultiShad('demo_gratuit');
                }
            }
        }, 100);
    }
}


 /* pour les tableaux du guide des tailles */
function fadeTaille(id, nbelem) {
    for (var i = 1; i <= nbelem; i++) {

        if (id != i && $("#content_quide_" + i).css('display') == 'block') {

            $("#content_quide_" + i).slideUp(400);
            $("#intitule_quide_" + i).removeClass('actif');
        }
    }

    if ($("#content_quide_" + id).css('display') != 'block') {

        $("#content_quide_" + id).slideDown(400);
        $("#content_quide_" + id).addClass('actif');
        $("#intitule_quide_" + id).addClass('actif');
    } else {

        $("#content_quide_" + id).slideUp(400);
        $("#content_quide_" + id).removeClass('actif');
        $("#intitule_quide_" + id).removeClass('actif');
    }
}

function openMultiShad(id) {

    // Show modbox and make sure closing routine won't fire more than once
    $("#" + id).fadeIn(200, function () {

        $("#" + id).slideDown(600);
    });

    $("#shade").css("display", "block").fadeTo("slow", 0.8).on("click", function () {

        closeMultiShad(id);
    });

    if(id == 'demo_gratuit' && $('#left_menu').hasClass('open')){
        toggleMenu('left', 'left');
    }
}

// Add class lookbook on page lookbook
$("body.category").each(function(){
    if($(this).find(".container_products").hasClass("container_products_lookbook")){
        $(this).addClass("lookbook");
    }
});

var eresa_no_stock = true;

$(function(){
    /* Swiper alphabet page abcdaire */
    if($('body.abcdaire .abecedaire_swiper').length) {
        var marques_navSwiperSlider = new Swiper(".abecedaire_swiper", {
            slidesPerView: 6,
            nextButton: ".swiper-button-next",
            prevButton: ".swiper-button-prev"
        });
    }

    /* Smooth scrolling et offset pour le clique sur les ancres page abcdaire  */
    $('.marques_list').each(function(){
        $(this).on('click touch', function(){
            var targetedBloc = $(this).find('a').attr('href');
            $("html, body").animate({ scrollTop: $(targetedBloc).offset().top - 60 }, 800);
        });
    });

    // CMS PAGE RECRUTEMENT OFFRES
    if($('.offre-module').length){
        $('.cms-page-column + .cms-page-column').slideUp(400);
        $('.cta-details').on('click touch', function(e){
            e.preventDefault();
            var columnParent = $(this).parent().parent().parent();
            columnParent.next('.cms-page-column').slideToggle(400);

            //Change CTA to "Fermer le détail" ou "Voir le détail" selon la status du btn
            if($(this).find('.button').text() == "Voir le détail"){
                $(this).find('.button').text(translate('close_details'));
            } else {
                $(this).find('.button').text(translate('see_details'));
            }

            $('.cta-details').not(this).each(function(){
                //Change CTA to "Voir le détail"    
                $(this).find('.button').text(translate('see_details'));
                
                var otherParent = $(this).parent().parent().parent().next('.cms-page-column');

                otherParent.each(function() {
                    if($(this).css('display') == 'block'){
                        $(this).slideToggle(400);
                    }
                });
            });
        });

        if($("select[name='poste']").length){    
            /*Changement du select lorsqu'on clique sur "Postuler" */
            $('.cta-postuler').on('click', function(e){
                e.preventDefault();
                var post = $(this).parent().parent().parent().parent().parent().parent();
                var postInx = post.index();
                var selectPost = $("select[name='poste'] option:eq(" + postInx + ")").val();
                wShop.$refs.wRecrutmentForm.form.poste = selectPost;

                $('html,body').animate({
                    scrollTop: $("#sticky_form_carriere").offset().top - 150
                }, 'slow');
    
                if(selectPost.trim() === 'Candidature spontanée'){
                    $('html,body').animate({
                        scrollTop: $("#sticky_form_carriere").offset().top - 150
                    }, 'slow');
                }
    
                if($(".w-recrutment-form .w-dropdown").hasClass('aSeeker')){
                    $(".w-recrutment-form .w-dropdown").removeClass('aSeeker');
                } else {
                    $(".w-recrutment-form .w-dropdown").addClass('aSeeker');
                }
    
                setTimeout(function(){
                    $(".w-recrutment-form .w-dropdown").addClass('aSeeker');
                }, 500);
            });

            $('.trigger_postuler').on('click', function(e){
                e.preventDefault();

                var getBtnParent = $(this).parent().parent();
                var getBtn = getBtnParent.find('.cta-postuler');
                //Postuler
                getBtn.click();
            });
        }
    }

    /*Charte de nommage*/
    if(!$('body').hasClass('homepage')){
        setTimeout(function () {
            $('.header_section.logo h1').replaceWith(function() {
                return $("<div>", {
                class: this.className,
                html: $(this).html()
                });
            });
        }, 800);
    }

    $('.misc_cookiesinfo .content_sat .cookieShowLightbox').click(function() {
        $('.misc_cookiesinfo #cookiesLightbox').addClass('displayCookies');
        if($('.misc_cookiesinfo #cookiesLightbox').has('.hideCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('hideCookies');
        }
        $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.misc_cookiesinfo #js_cookie_refuse, .misc_cookiesinfo #js_cookie_accept').click(function() {
        $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').removeClass('hideLoader');
        if($('.misc_cookiesinfo #cookiesLightbox').has('.displayCookies')) {
            setTimeout(() => {
                $('.misc_cookiesinfo #cookiesLightbox').removeClass('displayCookies');
                $('.misc_cookiesinfo #cookiesLightbox').addClass('hideCookies');
                $('.misc_cookiesinfo #shadCookie').removeClass('actif');
                $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').removeClass('loading');
            }, 500);
        }
    });
});

$(document).ready(function() {
    var select_conctact_dropdown = $(".bloc_nous_contacter #contact_form .w-contact-form select");
    select_conctact_dropdown.niceSelect().addClass('wide');
    $('.nice-select.w-input-element').addClass('wide')

    select_conctact_dropdown.on("click", function() {
        $('.nice-select.w-input-element').toggleClass('open_me').addClass('wide open');
        $('.nice-select.w-input-element .list').slideToggle(300);
    });

    select_conctact_dropdown.on("change", function() {
        wShop.$refs.wContactForm.form.form_service = this.value;
    });
})

// PAGES SATELLITE protection-donnees-personnelles.php
$(document).ready(function () {
    $("body.footer_privacy #row_bloc_3 .content_inside h3").each(function(){

        $("h3 + p + h3 + p + h3").prev().prev().prev().prev().addClass("initial");
        $(this).on("click", function(){
            if(!$(this).next("p").hasClass("active")){
                $("body.footer_privacy #row_bloc_3 .content_inside h3").removeClass("active");
                $("body.footer_privacy #row_bloc_3 .content_inside h3 + p").removeClass("active").slideUp();
                $(this).addClass("active");
                $(this).next("p").addClass("active").slideDown();
            }
            else{
                $(this).removeClass("active");
                $(this).next("p").removeClass("active").slideUp();
            }
            if($(this).hasClass('active') && !$(this).hasClass('initial')){
                $('html, body').animate({scrollTop: $("h3.active").prev().prev().offset().top - 100}, 500);
            }
        });

    });
});

$(document).ready(function () {
    setTimeout(function() {
        var menu = $('.main_menu_itm');

        menu.each(function() {
            if($(this).hasClass('active') && $(this).find('.block_lnk').hasClass('has_submenu')){
                $(this).find('.block_lnk').toggleClass('showSubcat');
            }

            if($(this).find('.block_lnk').hasClass('has_submenu')) {     
                $(this).find('.block_lnk').on("click", function(e) {
                    e.preventDefault();

                    $(this).toggleClass('showSubcat');
                    $(this).next('.submenu_wrapper').slideToggle();
                });
            }
        });

    }, 100);
});